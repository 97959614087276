/* eslint-disable no-mixed-operators */
'use strict';

// Constants
const helpers = {};

// API includes
var libphonenumber = require('libphonenumber-js');

/**
 * Collection of validation functions.
 */
helpers.isValid = {
    /**
     * Verify if the provided nif (Portuguese VAT IN) is valid.
     * @param {string} value - nif (Portuguese VAT IN) to validate
     * @returns {boolean} true if is a valid nif
     */
    nif: function (value) {
        var valid;
        var pattern1 = ['1', '2', '3', '5', '6', '8'];
        var pattern2 = ['45', '70', '71', '72', '77', '79', '90', '91', '98', '99'];

        if (pattern1.indexOf(value.substr(0, 1)) === -1 && pattern2.indexOf(value.substr(0, 2)) === -1) {
            valid = false;
        }

        var total =
            value[0] * 9 +
            value[1] * 8 +
            value[2] * 7 +
            value[3] * 6 +
            value[4] * 5 +
            value[5] * 4 +
            value[6] * 3 +
            value[7] * 2;

        var module11 = total - parseInt(total / 11, 10) * 11;
        var comparator = module11 === 1 || module11 === 0 ? 0 : 11 - module11;

        valid = parseInt(value[8], 10) === comparator;


        if (!valid) {
            var letras = 'TRWAGMYFPDXBNJZSQVHLCKET';
            var numero;
            var letraCalculada;
            var dni = value.toUpperCase();

            if (dni.length === 9 && /^[XYZ0-9]/.test(dni.charAt(0)) && /^[A-Z]$/.test(dni.charAt(8))) {
                numero = dni.substr(0, 8);

                if (['X', 'Y', 'Z'].indexOf(dni.charAt(0)) !== -1) {
                    numero = numero.replace('X', '0').replace('Y', '1').replace('Z', '2');
                }

                letraCalculada = letras.charAt(parseInt(numero, 10) % 23);

                if (letraCalculada === dni.charAt(8)) {
                    valid = true;
                } else {
                    valid = false;
                }
            }
        }

        return valid;
    },

    /**
     * Verify if the provided phone number is valid for the country and if it is returns a phoneNumber object.
     * @param {string} value - phone number to validate
     * @param {string} country - country code
     * @returns {Object | boolean} true if is a valid phone number
     */
    phoneNumber: function (value, country) {
        var isValid = libphonenumber.isValidNumber(value, country);
        if (isValid) {
            return libphonenumber.parsePhoneNumberFromString(value, country);
        }
        return false;
    }
};

module.exports = helpers;
