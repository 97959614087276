'use strict';

var base = require('base/login/login');

// Component includes
var createErrorNotification = require('base/components/errorNotification');
var formValidation = require('base/components/formValidation');

// Util includes
var cookiesUtil = require('../utils/cookies');
var states = require('improove/util/states');

// Helper includes
var validationHelper = require('../helpers/validation');

/**
 * Validates phone number and changes frontend classes and error messages accordingly
 * @param {number} value - phone number
 * @returns {boolean} true if is a valid phone number
 */
function validatePhoneNumber(value) {
    if (value) {
        var selectedCountry = document.querySelector('[class="styled-select selected-country-flag"]').innerText.trim();
        var phoneNumber = validationHelper.isValid.phoneNumber(value, selectedCountry);

        // @todo - solution implemented is not valid for US because in the US the + is replaced with 011
        if (phoneNumber && phoneNumber.isValid()) {
            var phoneNumberCountry = phoneNumber.country;
            if (phoneNumberCountry === selectedCountry) {
                $('#registration-form-phone').val(phoneNumber.number.replace('+', '00'));
                document.cookie = 'phoneValid=true';
                $('#registration-form-phone').removeClass('is-invalid');
                $('#registration-form-phone').removeClass('.invalid-feedback').empty();
            } else {
                $('#registration-form-phone').val(phoneNumber.number.replace('+', '00'));
                document.querySelector('[countrycode="' + phoneNumber.country + '"]').click();
                document.cookie = 'phoneValid=true';
                $('#registration-form-phone').removeClass('is-invalid');
            }
            return true;
        }
        $('#registration-form-phone').addClass('is-invalid');
        $('#registration-form-phone').siblings('.invalid-feedback').empty();
        $('#registration-form-phone')
            .siblings('.invalid-feedback')
            .append(document.getElementById('error-account-Phone').getAttribute('data-error-phone'));
        document.cookie = 'phoneValid=false';
    } else {
        document.cookie = 'phoneValid=false';
    }
    return false;
}

$(document).ready(function () {
    var homaNewAccount = cookiesUtil.getCookie('createAccountHoma');
    if (homaNewAccount === 'true' || homaNewAccount === true) {
        var addressObject;
        var localStorageAddress = localStorage.getItem('AddressParsed');
        if (localStorageAddress) {
            // get the wanted cookie
            var AddressFromCookie = localStorageAddress;
            if (AddressFromCookie) {
                AddressFromCookie = JSON.parse(AddressFromCookie);
                addressObject = {
                    firstName: AddressFromCookie.firstName,
                    lastName: AddressFromCookie.lastName,
                    address1: AddressFromCookie.address1,
                    address2: AddressFromCookie.address2,
                    city: AddressFromCookie.city,
                    postalCode: AddressFromCookie.postalCode,
                    stateCode: AddressFromCookie.stateCode,
                    countryCode: AddressFromCookie.countryCode,
                    phone: AddressFromCookie.phone,
                    nif: AddressFromCookie.nif,
                    email: AddressFromCookie.email
                };
            }
        } else {
            addressObject = {
                firstName: null,
                lastName: null,
                address1: null,
                address2: null,
                city: null,
                postalCode: null,
                stateCode: null,
                countryCode: null,
                phone: null,
                nif: null,
                email: null
            };
        }
        $('#registration-form-fname').val(addressObject.firstName);
        $('#registration-form-lname').val(addressObject.lastName);
        $('#registration-form-email-confirm').val(addressObject.email);
        $('#registration-form-nif').val(addressObject.nif);
        $('#registration-form-email').val(addressObject.email);
        $('#registration-form-phone').val(addressObject.phone);
        $('#registration-form-email-confirm').val(addressObject.email);
        // click to show register page
        document.querySelector('[aria-controls="register"]').click();

        document.cookie = 'createAccountHoma=';
    }

    $('#registerPhoneId').each(function () {
        var countries = [
            { countryCode: 'pt-PT', name: 'Portugal' },
            { countryCode: 'es-ES', name: 'Spain' },
            { countryCode: 'at-AT', name: 'Austria' },
            { countryCode: 'be-BE', name: 'Belgium' },
            { countryCode: 'bg-BG', name: 'Bulgaria' },
            { countryCode: 'hr-HR', name: 'Croatia' },
            { countryCode: 'cy-CY', name: 'Cyprus' },
            { countryCode: 'cz-CZ', name: 'Czech Republic' },
            { countryCode: 'dk-DK', name: 'Denmark' },
            { countryCode: 'ee-EE', name: 'Estonia' },
            { countryCode: 'fi-FI', name: 'Finland' },
            { countryCode: 'fr-FR', name: 'France' },
            { countryCode: 'de-DE', name: 'Germany' },
            { countryCode: 'gr-GR', name: 'Greece' },
            { countryCode: 'hu-HU', name: 'Hungary' },
            { countryCode: 'ie-IE', name: 'Ireland' },
            { countryCode: 'it-IT', name: 'Italy' },
            { countryCode: 'lv-LV', name: 'Latvia' },
            { countryCode: 'lt-LT', name: 'Lithuania' },
            { countryCode: 'lu-LU', name: 'Luxembourg' },
            { countryCode: 'mt-MT', name: 'Malta' },
            { countryCode: 'nl-NL', name: 'Netherlands' },
            { countryCode: 'pl-PL', name: 'Poland' },
            { countryCode: 'ro-RO', name: 'Romania' },
            { countryCode: 'sk-SK', name: 'Slovakia' },
            { countryCode: 'si-SI', name: 'Slovenia' },
            { countryCode: 'se-SE', name: 'Sweden' },
            { countryCode: 'gb-GB', name: 'United Kingdom' },
            { countryCode: 'ru-RU', name: 'Russia' },
            { countryCode: 'us-US', name: 'United States' }
        ];
        // Cache the number of options
        var $this = $(this);
        var numberOfOptions = countries.length;

        // Hides the select element
        $this.addClass('s-hidden');

        // Wrap the select element in a div
        $this.wrap('<div class="select phone-flag-login"></div>');

        // Insert a styled div to sit over the top of the hidden select element
        $this.after('<div class="styled-select selected-country-flag"></div>');

        // Cache the styled div
        var $styledSelect = $this.next('div.styled-select');

        var selectedItem;

        // Show the select option according to the current locale in the styled div
        for (var j = 0; j < numberOfOptions; j++) {
            if ($this.children('option').eq(j).attr('selected')) {
                selectedItem = $this.children('option').eq(j);
            }
        }

        var styledItemImg = '<img class="country-flag" src="' + selectedItem.val() + '" alt="" />';
        var styledItemText = '<span class="country-text">' + selectedItem.text() + '</span>';

        $styledSelect.html(styledItemImg + styledItemText);

        // Insert an unordered list after the styled div and also cache the list
        var $list = $('<ul />', {
            class: 'options'
        }).insertAfter($styledSelect);

        // Insert a list item into the unordered list for each select option
        for (var i = 0; i < numberOfOptions; i++) {
            var img =
                '<img class="country-flag" src="' +
                $this.children('option').eq(i).val() +
                '" alt="' +
                countries[i].name +
                '" countrycode="' +
                countries[i].countryCode +
                '" />';
            var text = '<span class="country-text">' + $this.children('option').eq(i).text() + '</span>';
            $('<li class="options-item" />')
                .html(img + text)
                .appendTo($list);
        }

        // Cache the list items
        var $listItems = $list.children('li');

        // Selecciona todos los elementos que contienen el atributo 'countrycode'
        const countryOptions = $('.options-item');

        // Selecciona el input hidden
        const hiddenInput = $('#selectedCountryCode');

        // Itera sobre cada opción y agrega un evento click
        countryOptions.each(function () {
            $(this).on('click', function () {
                const countryCode = $(this).find('.country-flag').attr('countrycode');
                hiddenInput.val(countryCode); // Actualiza el valor del input hidden
            });
        });


        // Show the unordered list when the styled div is clicked (also hides it if the div is clicked again)
        $styledSelect.click(function (e) {
            e.stopPropagation();
            $('div.styled-select.active').each(function () {
                $(this).removeClass('active').next('ul.options').hide();
            });
            $(this).toggleClass('active').next('ul.options').toggle();
        });

        // Hides the unordered list when a list item is clicked and updates the styled div to show the selected list item
        // Updates the select element to have the value of the equivalent option
        $listItems.click(function (e) {
            e.stopPropagation();
            $styledSelect.html($(this).html()).removeClass('active');
            $list.hide();
        });

        // Hides the unordered list when clicking outside of it
        $(document).click(function () {
            $styledSelect.removeClass('active');
            $list.hide();
        });
    });
});

$('.shippingNifNumber').on('change', function () {
    var isValid = validationHelper.isValid.nif($('#registration-form-nif').val());
    if (isValid) {
        $('.shippingNifNumber').removeClass('is-invalid');
        $('.shippingNifNumber').siblings('.invalid-feedback').empty();
    } else {
        $('.shippingNifNumber').addClass('is-invalid');
        $('.shippingNifNumber').siblings('.invalid-feedback').empty();
        $('.shippingNifNumber').siblings('.invalid-feedback').append(window.validPhoneNif);
    }
});

$('#registration-form-email').on('change', function () {
    $('.getSubscribeOnAccount').val($('#registration-form-email').val());
});

$('#registration-form-birthday').on('focusout', function () {
    var val = this.value.split('-');
    if (val[0] > new Date().getFullYear() || val === '') {
        $('#registration-form-birthday').val(new Date().getFullYear());
        // we have the error stored at hidden phone error span.
        $('#form-birthday-error').append(
            document.getElementById('error-account-Phone').getAttribute('data-error-birthday')
        );
    } else {
        $('#form-birthday-error').append('');
    }
});

// remove error message from read and accept terms
$('#read-and-accept').click(function () {
    if ($('#form-readAndAccept-error').text().trim() !== '' && $('#read-and-accept').is(':checked') === true) {
        $('#form-readAndAccept-error').text('');
    }
});

// edit registration - trigger
$('.edit-registration-form').click(function () {
    $('.submit-account-pin-validation').attr('disabled', false);
    $('.registration-form').removeClass('visually-hidden');
    $('.validate-pin-form').addClass('visually-hidden');
    $('.submit-account-pin-validation').removeClass('visually-hidden');
    $('.submit-account').addClass('visually-hidden');
    $('.pin-validation-feedback').text('');
    $('.pin-validation-feedback').addClass('visually-hidden');
    $('.submit-account').attr('disabled', true);
    $('.pin-validation-button').attr('disabled', false);
});

// account pin validation - trigger
$('.submit-account-pin-validation').click(function () {
    var regex = /^[\w.%+-]+@[\w.-]+\.[\w]{2,6}$/;
    var isRegexValid = false;
    if (regex.test($('#registration-form-email').val() && $('#registration-form-email-confirm').val())) {
        isRegexValid = true;
    }

    if (
        $('#form-fname-error').text() !== '' ||
        $('#registration-form-fname').val() === '' ||
        $('#form-lname-error').text() !== '' ||
        $('#registration-form-lname').val() === '' ||
        $('#form-phone-error').text() !== '' ||
        $('#registration-form-phone').val() === '' ||
        $('#form-email-error').text() !== '' ||
        $('#registration-form-email').val() === '' ||
        $('#form-email-confirm-error').text() !== '' ||
        $('#registration-form-email-confirm').val() === '' ||
        $('#form-password-error').text() !== '' ||
        $('#registration-form-password').val() === '' ||
        $('#form-password-confirm-error').text() !== '' ||
        $('#registration-form-password-confirm').val() === '' ||
        $('#form-readAndAccept-error').text() !== '' ||
        $('#read-and-accept').val() === 'false' ||
        !isRegexValid
    ) {
        $('.submit-account-form-validation').click();
    } else {
        $('.submit-account-pin-validation2').click();
    }
});

$('body').on('click', '.nav-account', function (e) {
    e.preventDefault();
    window.scrollTo(0, 0);
});

$('.submit-account-pin-validation2').click(function (e) {
    e.preventDefault();
    $.spinner().start();
    $('.submit-account-pin-validation').attr('disabled', true);
    var isPhoneValid = validatePhoneNumber($('#registration-form-phone').val());

    if (isPhoneValid) {
        var form = $('.registration');
        var url = $('.validate-registration-data').attr('validate-registration-action');

        $.ajax({
            url: url,
            type: 'post',
            dataType: 'json',
            data: form.serialize(),
            success: function (data) {
                if (data.errorApi === true) {
                    createErrorNotification($('.error-messaging'), data.errorMessage);
                    $('.submit-account-pin-validation').attr('disabled', false);
                } else if (!data.success && data.errorApi !== true) {
                    formValidation(form, data);
                    $('.submit-account-pin-validation').attr('disabled', false);
                } else if (data.success) {
                    setTimeout(() => {
                        $('.registration-form').addClass('visually-hidden');
                        $('.validate-pin-form').removeClass('visually-hidden');
                        $('.submit-account-pin-validation').addClass('visually-hidden');
                        $('.submit-account').removeClass('visually-hidden');
                    }, 1000);
                } else {
                    createErrorNotification($('.error-messaging'), data.errorMessage);
                    $('.submit-account-pin-validation').attr('disabled', false);
                }
                $.spinner().stop();
            },
            error: function () {
                createErrorNotification($('.error-messaging'), 'ERROR');
                $('.submit-account-pin-validation').attr('disabled', false);
            }
        });
    } else {
        $.spinner().stop();
        $('.submit-account-pin-validation').attr('disabled', false);
    }
});

// confirm registration pin - trigger
$('.pin-validation-button').click(function (e) {
    e.preventDefault();

    $('.pin-validation-button').attr('disabled', true);

    var email = $('#registration-form-email').val();
    var form = $('.validate-pin-form').serialize() + '&sessionId=' + email;
    var url = $('.validate-registration-data').attr('validate-account-pin-action');
    $.spinner().start();

    $.ajax({
        url: url,
        type: 'post',
        dataType: 'json',
        data: form,
        success: function (data) {
            if (data.errorApi) {
                createErrorNotification($('.error-messaging'), data.errorMessage);
                $('.pin-validation-button').attr('disabled', false);
            } else if (!data.success) {
                $('.pin-validation-button').attr('disabled', false);
                $('.submit-account').attr('disabled', true);
                $('.pin-validation-feedback').text(data.message);
                $('.pin-validation-feedback').removeClass('visually-hidden');
            } else if (data.success) {
                document.cookie = 'isValidPin=true; path=/; SameSite=Strict; Secure';

                $('.pin-validation-feedback').text(data.message);
                $('.pin-validation-feedback').removeClass('visually-hidden');

                $('.registration').submit();

                $('.pin-validation-button').attr('disabled', true);
            }
        },
        error: function () {
            createErrorNotification($('.error-messaging'), 'ERROR');
            $('.pin-validation-button').attr('disabled', false);
        },
        complete: function () {
            $.spinner().stop();
        }
    });
});

base.state = function () {
    states.add('reset-password', $('.state-container--reset-password'), $('.resetPassword-state-trigger'), {});
};

base.openAccountMenu = () => {
    $('[data-open-account-menu]').on('click', (e) => {
        e.preventDefault();
        var accountMenuCollapsible = $('.menu-account .collapsible');
        if (!accountMenuCollapsible.hasClass('active')) {
            accountMenuCollapsible.addClass('active');
        }
    });
};

base.resetPassword = function () {
    $('.reset-password-form').submit(function (e) {
        var form = $(this);
        e.preventDefault();
        var url = form.attr('action');
        form.spinner().start();
        $('.reset-password-form').trigger('login:register', e);
        $.ajax({
            url: url,
            type: 'post',
            dataType: 'json',
            data: form.serialize(),
            success: function (data) {
                form.spinner().stop();
                if (!data.success) {
                    formValidation(form, data);
                } else {
                    $('.request-password-title').text(data.receivedMsgHeading);
                    $('.request-password-body')
                        .empty()
                        .append('<p>' + data.receivedMsgBody + '</p>');
                    $('.send-email-btn > #submitEmailButton')
                        .empty()
                        .html('<a href="' + data.returnUrl + '" class="button--special">' + data.buttonText + '</a>');
                }
            },
            error: function () {
                form.spinner().stop();
            }
        });
        return false;
    });
};

module.exports = base;
