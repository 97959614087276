'use strict';

/* global request */

/**
 * Checks if the cookie is set and returns it's value
 * @param {string} cookieName - Cookie name
 * @return {(boolean|string)} cookie value or false
 */
function getCookie(cookieName) {
    var name = cookieName + '=';
    var decodedCookie = decodeURIComponent(document.cookie);
    var cookieArray = decodedCookie.split(';');
    for (var i = 0; i < cookieArray.length; i++) {
        var cookieItem = cookieArray[i];
        while (cookieItem.charAt(0) === ' ') {
            cookieItem = cookieItem.substring(1);
        }
        if (cookieItem.indexOf(name) === 0) {
            return cookieItem.substring(name.length, cookieItem.length);
        }
    }
    return false;
}

module.exports = {
    getCookie: getCookie
};
